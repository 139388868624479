import io from "socket.io-client";
import store from "../store";
import { sendLogout, sendMapAction } from "@/components/MessageBus";

const identifyListener = (type, text) => {
  if (type == "UCP") {
    // console.log("senha");
    sendLogout();
  } else if (type == "UCR") {
    // console.log("perfil");
    sendLogout();
  }
};

export default (privateSocket, globalSocket) => {
  localStorage.debug = "*";
  const socket = io.connect({
    reconnection: true, // Habilita a reconexão automática
    reconnectionDelay: 1000, // Tempo inicial de delay para tentativas de reconexão
    reconnectionDelayMax: 5000, // Tempo máximo de delay para tentativas de reconexão
    reconnectionAttempts: Infinity, // Número ilimitado de tentativas de reconexão
    query: { id: privateSocket, global: globalSocket },
  });

  // window.vue.$on("LOSTFOCUS", () => {
  //   console.log("LOSTFOCUS");
  // });

  // window.vue.$on("INFOCUS", () => {
  //   console.log("INFOCUS");
  // });

  socket.on("connect", () => {
    store.dispatch("setOn");
  });

  socket.on("disconnect", () => {
    store.dispatch("setOff");
  });

  socket.on("connect_error", error => {
    store.dispatch("setOff");
  });

  // console.log(privateSocket, globalSocket, socket);

  socket.off(privateSocket);
  socket.on(privateSocket, (msg) => {
    if (msg && msg.type && msg.text) {
      identifyListener(msg.type, msg.text);
    }
  });
  socket.off(globalSocket);
  socket.on(globalSocket, (msg) => {
    // console.log('global chanel', msg)
    if (store) {
      let actionConverted = {
        d : 'R',
        c : 'A',
        u : 'U'
      }
      sendMapAction(actionConverted[msg.action], {
        featureType: msg.data.properties ? msg.data.properties.featureType : null,
        feature: msg.data ? msg.data: null,
        id: msg.data.properties.id,
        folderId: msg.data.properties ? msg.data.properties.folderId : null
      })
      store.dispatch("setNotification", true);
      console.log("SET NOTIFICATION", msg);
    }
  });
};
