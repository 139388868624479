<template>
  <div class="alert-container">
    <b-alert
      v-for="(message, index) in messages"
      :key="index"
      show
      fade
      dismissible
      :variant="message.type"
    >
    <span v-html="message.text"></span>
    </b-alert>
  </div>
</template>

<script>
import {
  sucessMessageListener,
  errorMessageListener,
  infoMessageListener,
  messageBus,
} from "./MessageBus";

export default {
  name: "MessageScreen",
  data() {
    return {
      messages: [],
      financial: {},
      baseTimeout: 3500,
    };
  },
  methods: {
    addErrorMessage(text) {
      this.messages.push({
        type: "danger",
        text,
      });
      this.removeAlert(text);
    },
    addSuccessMessage(text) {
      this.messages.push({
        type: "success",
        text,
      });
      this.removeAlert(text);
    },
    addInfoMessage(text) {
      this.messages.push({
        type: "warning",
        text,
      });
      this.removeAlert(text);
    },
    calculateTimeout(text) {
      const charCount = text.length;

      if (charCount <= 70) {
        return this.baseTimeout;
      }

      const additionalTimeout = Math.min((charCount - 70) * 50, 6500);
      return this.baseTimeout + additionalTimeout;
    },
    removeAlert(text) {
      const timeout = this.calculateTimeout(text);
      setTimeout(() => {
        this.messages.shift();
      }, timeout);
    },
  },
  mounted() {
    messageBus.$on(sucessMessageListener, (message) => {
      this.addSuccessMessage(message);
    });
    messageBus.$on(errorMessageListener, (message) => {
      this.addErrorMessage(message);
    });
    messageBus.$on(infoMessageListener, (message) => {
      this.addInfoMessage(message);
    });
  },
};
</script>

<style>
.alert-container {
  position: fixed;
  top: 5px;
  z-index: 10002;
  width: 25%;
  right: 15px;
}
</style>
