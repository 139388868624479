<template>
  <div v-if="isLogged">
    <b-navbar type="dark" style="z-index: 999; background-color: #020f40" class="navbar-main">
      <router-link to="/">
        <img style="height: 40px" src="../../assets/img/logo_branca.png" />
      </router-link>

      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <div class="version">
            <LoggedUser />
          </div>

          <Notifications />
          <sideBar :exit="exit" />

          <div class="c-pointer m-2" right>
            <router-link id="linkFaq" to="/faq">
              <b-icon icon="question-circle-fill" style="color: #ffff" title="Central de ajuda"></b-icon>
            </router-link>
          </div>

          <div class="h4 c-pointer m-1" right>
            <b-icon v-b-toggle.sidebar-right icon="list" style="color: #ffff" title="Menu"></b-icon>
          </div>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import sideBar from "./SideBar";
import LoggedUser from "./LoggedUser";
import Notifications from "./Notifications";

import { openPopupMessage } from "../../view/map/popup";

export default {
  name: "HeaderApp",
  components: {
    sideBar,
    LoggedUser,
    Notifications,
  },
  computed: {
    ...mapGetters({
      isLogged: "isLogged",
      hasNotification: "hasNotification",
    }),
  },
  methods: {
    exit() {
      this.$router.replace("login", () => {
        // localStore.set(null);
        this.logout();
      });
    },
    openMessage() {
      openPopupMessage();
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 770) {
  .form-inline {
    align-items: baseline;
  }
}
@media only screen and (max-width: 395px) {
  img {
    margin-right: 0;
    padding-right: 0;
  }
  .mr-3 {
    margin-right: 0;
  }
  .form-inline {
    justify-content: flex-end;
  }
  .navbar-main {
    padding: 0;
  }
}
@media only screen and (max-width: 373px) {
  .version {
    display: none;
  }
}
</style>
